import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  color: #000;
  padding: 10px 10px 10px 0;
`;

export const Content = styled.div`
  background-color: #fff;
  border-radius: 40px;

  border: 1px solid #00000020;

  overflow: hidden;
`;

export const WhatsAppLinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: #51b7a1;

  padding: 20px;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;

    border-radius: 10px;
    background-color: #fff;
  }

  img {
    width: 60px;
    height: 60px;
  }

  a {
    color: #fff;
    font-size: 14px;

    max-width: 70%;
  }
`;

export const SectionTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 20px;

  flex-direction: column;

  top: 0;
  left: 0;

  height: max-content;

  width: 100%;

  color: #000;

  font-weight: 700;
  font-size: 20px;

  padding-top: 20px;

  z-index: 1;
`;

export const Description = styled.div`
  position: relative;
  font-size: 14px;

  padding: 0 40px 0px;

  max-width: 500px;
  text-align: center;

  font-weight: 500;
`;

export const ClientDetailsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;

  background-color: #00000090;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  z-index: 2;
`;

export const ClientDetailsContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  flex-direction: column;

  padding: 20px;

  background-color: var(--background);

  border-radius: 14px;

  max-width: 390px;
`;

export const PaymentMethodContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  gap: 4px;

  p {
    width: 100%;
    font-size: 12px;
    margin-bottom: 10px;

    text-align: center;

    padding-top: 10px;

    border-top: 1px solid #00000030;
  }
`;

export const Payment = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  gap: 4px;

  border: 1px solid #00000030;

  border-radius: 6px;

  padding: 2px 4px;

  cursor: pointer;

  ${props =>
    props.active &&
    css`
      border: 1px solid #fff;
      background-color: var(--content);
      color: #fff;
    `}

  img {
    width: 20px;
    height: 20px;
    object-fit: contain;
  }

  > span {
    font-size: 10px;
  }
`;

export const InfoTitle = styled.span`
  width: 100%;

  font-weight: 600;
  font-size: 14px;

  margin: 20px 0 10px 0;
`;

export const UploadContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;

  gap: 6px;

  padding: 4px 0;

  background-color: #fff;

  border-radius: 8px;

  border: 1px solid #00000020;
`;

export const LabelInputImg = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;

  padding-left: 10px;

  cursor: pointer;

  img {
    width: 70px;
    height: 70px;

    margin-right: 10px;

    border-radius: 10px;
  }
`;

export const UploadInformations = styled.div`
  span {
    display: block;
    font-size: 14px;
  }

  .title {
    font-weight: 500;
  }

  .info {
    font-size: 12px;
  }

  a {
    font-size: 12px;
  }
`;

export const InputUpload = styled.input`
  display: none;
`;

export const UploadButton = styled.button`
  width: 100%;
  height: 50px;

  border-radius: 8px;

  border: 1px solid #007bbf;
  background-color: #007bbf20;
  color: #007bbf;

  margin-top: 10px;

  &:disabled {
    background-color: #00000020;
    border: 1px solid #007bbf;
    color: #00000020;
  }
`;

export const InputAddress = styled.input`
  width: 100%;

  padding: 10px 12px;

  border-radius: 10px;

  outline: none;

  border: 1px solid #00000030;
`;

export const Buttons = styled.div`
  display: flex;
  width: 100%;

  gap: 8px;
`;

export const ButtonCancel = styled.button`
  width: 100%;

  margin-top: 16px;
  height: 50px;

  background-color: #00000010;
  color: #00000090;
  border-radius: 8px;

  font-size: 14px;

  border: none;
  outline: none;
`;

export const ButtonContinue = styled.button`
  width: 100%;

  margin-top: 16px;
  height: 50px;

  background-color: var(--primary);
  color: #fff;
  border-radius: 8px;

  font-size: 14px;

  border: none;
  outline: none;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: 10px;

  width: 100%;

  border: 1px solid transparent;
  border-radius: 12px;

  padding: 4px 14px;

  font-size: 14px;

  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;

  background-color: #fff;
`;

export const Input = styled.input`
  width: 100%;
  height: 42px;

  border: 1px solid transparent;
  background-color: transparent;
  font-size: 14px;
`;

export const Header = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;

  padding: 20px;

  background-color: #fff;

  margin-bottom: 10px;

  border: 1px solid #00000020;
  border-radius: 10px;

  div {
    font-weight: 500;
  }
`;

export const ProductList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
  flex: 1;

  padding: 20px;
`;

export const Product = styled.a`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;
  text-decoration: none;
  color: #000;

  background-color: #fff;

  border: 1px solid #00000020;

  padding: 6px;

  border-radius: 6px;

  transition: 0.2s ease;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;

  justify-content: space-between;

  width: 100%;

  font-size: 14px;
`;

export const Coupon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 14px;

  span {
    font-size: 14px;
  }

  img {
    width: 20px;
    height: 20px;
  }
`;

export const ValueContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 8px;

  background-color: var(--primary20);

  color: var(--primary);

  padding: 2px 8px;

  border-radius: 4px;

  font-size: 14px;
`;

export const DetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  font-size: 12px;
  margin-top: 4px;
`;

export const Date = styled.div`
  display: flex;
  align-items: center;

  font-size: 12px;

  position: relative;

  &.pending {
    color: #ffb800;
    font-weight: 500;

    &::after {
      content: '';
      position: absolute;

      width: 5px;
      height: 5px;
      border-radius: 50%;

      left: -10px;

      background-color: #ffb800;
    }
  }
`;

export const Empty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 8px;

  margin-top: 40px;
`;

export const Link = styled.a`
  padding: 10px 20px;
  border-radius: 10px;

  border: 1px solid var(--primary);
  background-color: var(--primary);

  color: #fff;

  text-decoration: none;
`;

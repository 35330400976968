import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  color: #000;
  padding: 10px 10px 10px 0;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 40px;

  padding: 20px;

  border: 1px solid #00000020;
`;

export const SectionTitle = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 20px;

  flex-direction: column;

  top: 0;
  left: 0;

  height: max-content;

  width: 100%;

  background-color: #fff;
  color: #000;

  font-weight: 700;
  font-size: 20px;

  padding-top: 20px;

  z-index: 1;

  img {
    width: 100%;
  }
`;

export const Description = styled.div`
  position: relative;
  font-size: 14px;
  color: #15151590;
  z-index: 1;

  padding: 0 40px 30px;

  max-width: 500px;
  text-align: center;

  font-weight: 500;
`;

export const CouponsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
`;

/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable radix */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Lottie from 'react-lottie';
import * as S from './styles';

import background from '../../assets/background.png';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import animationData from '../../assets/lottie/thesims.json';

// Função para validar e-mail
const validateEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

// Função para validar um número de celular no formato (99) 99999-9999
const validatePhoneNumber = (phone: string): boolean => {
  // Remove caracteres não numéricos
  phone = phone.replace(/\D/g, '');

  // Validação para garantir que o número tenha 11 dígitos (DDD + número)
  if (phone.length !== 11) return false;

  // Validação para garantir que o número não tenha todos os dígitos iguais (exemplo: 00000000000)
  if (/^(\d)\1+$/.test(phone)) return false;

  return true;
};

// Função para aplicar máscara de CPF
const formatPhoneNumber = (phone: string): string => {
  return phone
    .replace(/\D/g, '') // Remove qualquer caractere não numérico
    .replace(/(\d{2})(\d)/, '($1) $2') // Adiciona os parênteses ao DDD
    .replace(/(\d{5})(\d)/, '$1-$2') // Adiciona o traço após o quinto dígito do número
    .replace(/(-\d{4})\d+?$/, '$1'); // Garante que o número tenha apenas quatro dígitos após o traço
};

type SignProps = 'in' | 'up';

const Sign: React.FC = () => {
  const { addToast } = useToast();
  const { signIn } = useAuth();
  const [registerDone, setRegisterDone] = useState(false);
  const [quote, setQuote] = useState('Sistema de descontos desbloqueado!');
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const cod = query.get('code');
  const [sign, setSign] = useState<SignProps>(cod ? 'up' : 'in');

  // Estados para os inputs
  const [referralCode, setReferralCode] = useState(cod || '');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [whatsapp, setWhatsapp] = useState('');

  // Estados para os erros
  const [errorsSignUp, setErrorsSignUp] = useState({
    referralCode: '',
    email: '',
    name: '',
    lastName: '',
    password: '',
    confirmPassword: '',
    whatsapp: '',
  });

  const [errorsSignIn, setErrorsSignIn] = useState({
    whatsapp: '',
    password: '',
  });

  const validateReferralCode = useCallback(async (code: string) => {
    return false;
  }, []);

  const handleSignUp = useCallback(async () => {
    const newErrors = {
      referralCode: '',
      email: '',
      name: '',
      lastName: '',
      password: '',
      confirmPassword: '',
      whatsapp: '',
    };

    let valid = true;

    // Validação de e-mail
    if (!referralCode) {
      newErrors.referralCode = 'Código de indicação é obrigatório';
      valid = false;
    } else if (!validateReferralCode(referralCode)) {
      newErrors.referralCode = 'Código de indicação inválido';
      valid = false;
    }

    // Validação de e-mail
    if (!email) {
      newErrors.email = 'E-mail é obrigatório';
      valid = false;
    } else if (!validateEmail(email)) {
      newErrors.email = 'E-mail inválido';
      valid = false;
    }

    // Validação de nome completo
    if (!name) {
      newErrors.name = 'Nome é obrigatório';
      valid = false;
    }

    // Validação de nome completo
    if (!lastName) {
      newErrors.lastName = 'Sobrenome é obrigatório';
      valid = false;
    }

    // Validação de senha
    if (!password) {
      newErrors.password = 'Senha é obrigatória';
      valid = false;
    } else if (!confirmPassword) {
      newErrors.confirmPassword = 'Confirmação de senha é obrigatória';
      valid = false;
    } else if (password !== confirmPassword) {
      newErrors.password = 'As senhas não coincidem';
      valid = false;
    }

    // Validação de CPF
    if (!whatsapp) {
      newErrors.whatsapp = 'WhatsApp é obrigatório';
      valid = false;
    } else if (!validatePhoneNumber(whatsapp)) {
      newErrors.whatsapp = 'WhatsApp inválido';
      valid = false;
    }

    setErrorsSignUp(newErrors);

    if (valid) {
      try {
        const response = await api.post('user', {
          email,
          password,
          name,
          lastName,
          whatsapp,
          referralCode,
        });

        if (response.status === 201) {
          addToast({
            title: 'Código de indicação inválido.',
            type: 'error',
          });

          return;
        }

        if (response.status === 202) {
          addToast({
            title: `Código de indicação esgotado!`,
            type: 'info',
          });
          return;
        }

        if (response.status === 203) {
          addToast({
            title: `Whatsapp já cadastrado!`,
            description: 'Tente realizar login',
            type: 'info',
          });

          setSign('in');
          return;
        }

        setRegisterDone(true);
      } catch (err) {
        addToast({
          title: `Não foi possível criar seu usuário.`,
          description: 'Entre em contato com nossa equipe.',
          type: 'error',
        });
      }
    }
  }, [
    referralCode,
    validateReferralCode,
    email,
    name,
    lastName,
    password,
    confirmPassword,
    whatsapp,
    addToast,
  ]);

  const handleSignIn = useCallback(async () => {
    const newErrors = {
      whatsapp: '',
      password: '',
    };

    let valid = true;

    if (!whatsapp) {
      newErrors.whatsapp = 'Whatsapp é obrigatório';
      valid = false;
    } else if (!formatPhoneNumber(whatsapp)) {
      newErrors.whatsapp = 'Número celular inválido';
      valid = false;
    }

    // Validação de senha
    if (!password) {
      newErrors.password = 'Senha é obrigatória';
      valid = false;
    }

    setErrorsSignIn(newErrors);

    if (valid) {
      try {
        await signIn({
          whatsapp,
          password,
        });

        addToast({
          title: `Seja bem vindo!`,
          description: 'Você já pode adquirir seus cupons 🤩',
          type: 'success',
        });

        history.push('/');
      } catch (err) {
        addToast({
          title: `Não foi possível fazer login 😓`,
          description: 'Verifique suas credenciais',
          type: 'error',
        });

        // Chamar a função de cadastro
      }
    }
  }, [addToast, whatsapp, history, password, signIn]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        // Se a tecla pressionada for 'Enter', dispara a função do botão
        if (sign === 'up') {
          handleSignUp();
        } else {
          handleSignIn();
        }
      }
    },
    [handleSignIn, handleSignUp, sign],
  );

  useEffect(() => {
    if (registerDone) {
      setTimeout(() => {
        setQuote(
          'Você poderá indicar pessoas assim que fizer seu primeiro pedido!',
        );
        setTimeout(() => {
          setQuote('Seja bem vindo!');
          setTimeout(() => {
            handleSignIn();
          }, 1500);
        }, 3500);
      }, 3500);
    }
  }, [handleSignIn, registerDone]);

  return (
    <S.Container style={{ backgroundImage: `url(${background})` }}>
      <S.Shadow />

      {registerDone ? (
        <S.ContentContainer>
          <S.AnimationContainer>
            <Lottie
              options={{
                autoplay: true,
                loop: true,
                animationData,
              }}
              width={200}
            />
          </S.AnimationContainer>

          <S.Description>{quote}</S.Description>
        </S.ContentContainer>
      ) : (
        <S.ContentContainer>
          {sign === 'up' ? (
            <>
              {errorsSignUp.referralCode && (
                <S.ErrorMessage>{errorsSignUp.referralCode}</S.ErrorMessage>
              )}

              <S.Input
                placeholder="Código de indicação"
                value={referralCode}
                onChange={e => setReferralCode(e.target.value.toUpperCase())}
              />

              {errorsSignUp.whatsapp && (
                <S.ErrorMessage>{errorsSignUp.whatsapp}</S.ErrorMessage>
              )}

              <S.Input
                placeholder="WhatsApp"
                value={whatsapp}
                onChange={e => setWhatsapp(formatPhoneNumber(e.target.value))}
              />

              {errorsSignUp.password && (
                <S.ErrorMessage>{errorsSignUp.password}</S.ErrorMessage>
              )}
              <S.Input
                placeholder="Senha"
                type="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
              {errorsSignUp.confirmPassword && (
                <S.ErrorMessage>{errorsSignUp.confirmPassword}</S.ErrorMessage>
              )}
              <S.Input
                placeholder="Confirme sua senha"
                type="password"
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
              />

              {errorsSignUp.name && (
                <S.ErrorMessage>{errorsSignUp.name}</S.ErrorMessage>
              )}
              <S.Input
                placeholder="Nome"
                value={name}
                onChange={e => setName(e.target.value)}
              />

              {errorsSignUp.lastName && (
                <S.ErrorMessage>{errorsSignUp.lastName}</S.ErrorMessage>
              )}
              <S.Input
                placeholder="Sobrenome"
                value={lastName}
                onChange={e => setLastName(e.target.value)}
              />

              {errorsSignUp.email && (
                <S.ErrorMessage>{errorsSignUp.email}</S.ErrorMessage>
              )}
              <S.Input
                placeholder="Email"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />

              <S.ButtonSign onClick={handleSignUp}>Cadastrar</S.ButtonSign>

              <S.ButtonChangeSign
                onKeyDown={handleKeyDown}
                onClick={() => setSign('in')}
              >
                Já possuo conta, entrar.
              </S.ButtonChangeSign>
            </>
          ) : (
            <>
              {errorsSignIn.whatsapp && (
                <S.ErrorMessage>{errorsSignIn.whatsapp}</S.ErrorMessage>
              )}

              <S.Input
                placeholder="WhatsApp"
                value={whatsapp}
                onChange={e => setWhatsapp(formatPhoneNumber(e.target.value))}
              />

              {errorsSignIn.password && (
                <S.ErrorMessage>{errorsSignIn.password}</S.ErrorMessage>
              )}
              <S.Input
                placeholder="Senha"
                onKeyDown={handleKeyDown}
                type="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
              <S.ButtonSign onClick={handleSignIn}>Entrar</S.ButtonSign>

              <S.ButtonChangeSign onClick={() => setSign('up')}>
                Não tenho conta, cadastrar.
              </S.ButtonChangeSign>
            </>
          )}
        </S.ContentContainer>
      )}
    </S.Container>
  );
};

export default Sign;

/* eslint-disable import/no-extraneous-dependencies */
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { FiCopy, FiX } from 'react-icons/fi';
import Tilt from 'react-parallax-tilt';
import Lottie from 'react-lottie';
import copy from 'copy-to-clipboard';
import { useHistory } from 'react-router-dom';
import * as S from './styles';
import CouponSvg from '../../assets/coupon.svg';
import pixImg from '../../assets/pix.png';
import animationData from '../../assets/lottie/waiting.json';
import animationData2 from '../../assets/lottie/paymentdone.json';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import { IProduct } from '../../DTOS/IProduct';
import { useToast } from '../../hooks/toast';

type CouponProps = {
  id: string;
  coupon: number;
  price: number;
  minOrder: number;
  background: string;
};

const Coupon: React.FC<CouponProps> = ({
  id,
  coupon,
  price,
  minOrder,
  background,
}) => {
  const { user } = useAuth();

  const { addToast } = useToast();
  const { push } = useHistory();

  const [product, setProduct] = useState<IProduct>();

  const [isFlipped, setIsFlipped] = useState(false);
  const [paymentDone, setPaymentDone] = useState(false);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 700);
  const [active, setActive] = useState(false);
  const [copyCodeText, setCopyCodeText] = useState('Copiar Código');

  useEffect(() => {
    if (paymentDone) {
      addToast({
        title: 'Recebemos seu pagamento!',
        description: 'Estamos te redirecionando para um agente...',
        type: 'success',
      });
    }
  }, [addToast, paymentDone]);

  const generatePixPayment = useCallback(async () => {
    setActive(true);

    try {
      const response = await api.post<IProduct>('product', {
        userId: user.id,
        couponId: id,
        description: `${user.name} ${user.lastName} - ${user.id}`,
      });

      if (response.data) setProduct(response.data);

      setTimeout(() => {
        setIsFlipped(!isFlipped);
      }, 4000);
    } catch (error) {
      setActive(false);
      addToast({
        title: 'Erro ao gerar pagamento PIX!',
        type: 'error',
      });
      console.error('Erro ao gerar pagamento PIX', error);
    }
  }, [addToast, id, isFlipped, user.id, user.lastName, user.name]);

  const checkPaymentStatus = useCallback(async () => {
    if (!product) {
      addToast({
        title: 'Produto nao foi gerado',
        type: 'error',
      });
      return;
    }
    try {
      const response = await api.get(
        `product/check-payment-status/${product.payment.id}`,
      );

      if (response.data.status === 'approved') {
        setPaymentDone(true);

        setTimeout(() => {
          push(`/cupons`);
        }, 2000);
      }
    } catch (error) {
      addToast({
        title: 'Erro ao verificar status do pagamento',
        type: 'error',
      });
    }
  }, [addToast, product, push]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;

    if (product && product.payment.qrcode_base64 && !paymentDone) {
      // Inicia o intervalo de 1 segundo
      intervalId = setInterval(() => {
        checkPaymentStatus();
      }, 2000);
    }

    // Cleanup para limpar o intervalo quando o componente for desmontado ou se 'active' mudar para false
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [checkPaymentStatus, paymentDone, product]);

  useEffect(() => {
    // Aqui estamos pegando o tamanho da tela apenas na primeira renderização
    setIsLargeScreen(window.innerWidth > 700);
  }, []);

  const tiltMaxAngle = useMemo(() => {
    return [
      isFlipped || !isLargeScreen ? 0 : 20,
      isFlipped || !isLargeScreen ? 0 : 20,
    ];
  }, [isFlipped, isLargeScreen]);

  const handleCopyCode = useCallback(() => {
    if (product) {
      setCopyCodeText('Copiado!');
      copy(product.payment.qrcode);
      setTimeout(() => {
        setCopyCodeText('Copiar Código');
      }, 2000);
    }
  }, [product]);

  return (
    <Tilt
      tiltMaxAngleX={tiltMaxAngle[0]}
      tiltMaxAngleY={tiltMaxAngle[1]}
      transitionSpeed={400}
    >
      <S.CardContainer>
        <S.Card flipped={isFlipped}>
          <S.FrontFace />
          <S.BackFace style={{ backgroundImage: `url(${background})` }}>
            {product && (
              <>
                <S.WaitingPaymentContainer>
                  {paymentDone ? (
                    <Lottie
                      options={{
                        autoplay: true,
                        loop: false,
                        animationData: animationData2,
                        rendererSettings: {
                          preserveAspectRatio: 'xMidYMid slice',
                        },
                      }}
                      width={80}
                      height={70}
                    />
                  ) : (
                    <Lottie
                      options={{
                        autoplay: true,
                        loop: true,
                        animationData,
                        rendererSettings: {
                          preserveAspectRatio: 'xMidYMid slice',
                        },
                      }}
                      width={80}
                      height={70}
                    />
                  )}

                  {paymentDone ? (
                    <span />
                  ) : (
                    <span className="pending">Aguardando pagamento</span>
                  )}
                </S.WaitingPaymentContainer>

                {!paymentDone && (
                  <S.CloseButton onClick={() => setIsFlipped(!isFlipped)}>
                    <FiX size={14} />
                  </S.CloseButton>
                )}

                <S.PriceContainer>
                  {`R$ ${price.toFixed(2)} via PIX`}
                </S.PriceContainer>

                <S.CopyCodeContainer onClick={() => handleCopyCode()}>
                  {copyCodeText}
                  <FiCopy size={14} />
                </S.CopyCodeContainer>

                {product.payment.qrcode_base64 && (
                  <S.QrCodeContainer>
                    <S.QrCode
                      src={`data:image/png;base64,${product.payment.qrcode_base64}`}
                    />
                  </S.QrCodeContainer>
                )}
              </>
            )}
          </S.BackFace>
          <S.Container style={{ backgroundImage: `url(${background})` }}>
            <div className="backgroundShadow" />
            <div className="backgroundShadowGradient" />
            <div className="backgroundShadowGradient" />

            <S.Header>
              <S.HeaderImg src={CouponSvg} />
              <S.HeaderInfo>
                <span className="title">CUPOM IFOOD DE</span>
                <span className="value">{`R$ ${coupon}`}</span>
              </S.HeaderInfo>
            </S.Header>

            <S.ListOfBeneficits>
              <S.Beneficit>
                ∙ O cupom custa R$
                {` ${price.toFixed(2)}`}
              </S.Beneficit>
              <S.Beneficit>
                ∙ Recebe um cupom de R$
                {` ${coupon}`}
              </S.Beneficit>
              <S.Beneficit>
                ∙ Pedido minimo de
                {` R$ ${minOrder}`}
              </S.Beneficit>
              <S.Beneficit className="highlight">
                {` Economiza R$
        ${(coupon - price).toFixed(2)}
        no pedido`}
              </S.Beneficit>
            </S.ListOfBeneficits>
            <div className="roundLeft" />
            <div className="roundLeftSupport" />
            <div className="lineContainer">
              <div className="line" />
              <div className="line" />
              <div className="line" />
              <div className="line" />
              <div className="line" />
              <div className="line" />
              <div className="line" />
              <div className="line" />
              <div className="line" />
              <div className="line" />
              <div className="line" />
              <div className="line" />
              <div className="line" />
              <div className="line" />
            </div>
            <div className="roundRight" />
            <div className="roundRightSupport" />

            <S.Footer>
              <S.ButtonBuy
                active={active}
                disabled={active}
                onClick={generatePixPayment}
              >
                <img src={pixImg} alt="" />
                {!active ? (
                  <div>
                    <div>Pague com Pix</div>
                    <div style={{ fontSize: 10 }}>
                      Gere um QR Code para pagamento
                    </div>
                  </div>
                ) : (
                  <div>
                    <div>Gerando seu pedido</div>
                    <div style={{ fontSize: 10 }}>
                      Use o QR Code ou copie e cole o código
                    </div>
                  </div>
                )}
              </S.ButtonBuy>
            </S.Footer>
          </S.Container>
        </S.Card>
      </S.CardContainer>
    </Tilt>
  );
};

export default Coupon;

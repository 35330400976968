/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import imageCompression from 'browser-image-compression'; // Import da biblioteca de compressão de imagens
import { useHistory } from 'react-router-dom';
import * as S from './styles';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import { IProduct } from '../../DTOS/IProduct';
import animationData from '../../assets/lottie/empty.json';
import MiniCoupon from '../../components/MiniCoupon';
import imgUpload from '../../assets/imgupload.jpg';
import animationLoading from '../../assets/lottie/waiting.json';
import qrcodewpp from '../../assets/qrcodewpp.png';

type IMessageProps = {
  message?: string;
  imageUrl?: string;
};

type IPayment = {
  method: string;
  logo: string;
  selected: boolean;
};

const Coupons: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const { push } = useHistory();
  const [payments, setPayments] = useState<IPayment[]>([
    {
      method: 'Master Debito (entrega)',
      logo: 'https://upload.wikimedia.org/wikipedia/commons/0/04/Mastercard-logo.png',
      selected: false,
    },
    {
      method: 'Master Crédito (entrega)',
      logo: 'https://upload.wikimedia.org/wikipedia/commons/0/04/Mastercard-logo.png',
      selected: true,
    },
    {
      method: 'Visa Debito (entrega)',
      logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/41/Visa_Logo.png/640px-Visa_Logo.png',
      selected: false,
    },
    {
      method: 'Visa Crédito (entrega)',
      logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/41/Visa_Logo.png/640px-Visa_Logo.png',
      selected: false,
    },
    {
      method: 'Elo Debito (entrega)',
      logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhP1FUU4THhWbPkaGJBdRlJsn4PYfR19cH9w&s',
      selected: false,
    },
    {
      method: 'Elo Crédito (entrega)',
      logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhP1FUU4THhWbPkaGJBdRlJsn4PYfR19cH9w&s',
      selected: false,
    },
  ]);

  const [products, setProducts] = useState<IProduct[]>([]);
  const [searched, setSearched] = useState(false);

  const [image, setImage] = useState<File | null>(null);
  const [preview, setPreview] = useState<string | null>(null);

  const [uploading, setUploading] = useState(false);
  const [productSelected, setProductSelected] = useState<IProduct>();

  const [address, setAddress] = useState<any>(null);

  const getProducts = useCallback(async () => {
    try {
      const response = await api.get(`product/user/${user.id}`);

      setProducts(response.data);
      setSearched(true);
    } catch (err) {
      addToast({
        title: 'Não foi possivel buscar contadores do agente.',
        type: 'error',
      });
    }
  }, [addToast, user.id]);

  useEffect(() => {
    getProducts();

    setInterval(() => {
      getProducts();
    }, 10000);
  }, [getProducts]);

  // Função para comprimir e fazer o upload da imagem
  const handleImageChange = async (
    e: ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    const file = e.target.files?.[0];
    if (file) {
      setImage(file);
      const reader = new FileReader();
      reader.onloadend = () => setPreview(reader.result as string);
      reader.readAsDataURL(file);
    }
  };

  // Função para comprimir a imagem
  const compressImage = async (img: File): Promise<File> => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 800,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(img, options);
      return compressedFile;
    } catch (error) {
      console.error('Erro ao comprimir a imagem:', error);
      throw error;
    }
  };

  const handleSentMessage = useCallback(
    async ({ message, imageUrl }: IMessageProps) => {
      if (!message && !imageUrl) return;
      if (!productSelected) return;

      try {
        await api.post('product/message', {
          product_id: productSelected.id,
          sender_id: user.id,
          content: message,
          imageUrl,
        });
      } catch {
        addToast({
          title: 'Não foi possível enviar mensagem',
          type: 'error',
        });
      }
    },
    [productSelected, user.id, addToast],
  );

  const handleSubmit = async (): Promise<void> => {
    if (!image || !productSelected) return;

    setUploading(true); // Inicia o estado de upload

    const payment = payments.find(p => p.selected);

    if (!payment) return;

    try {
      // Comprimir a imagem antes do envio
      const compressedImage = await compressImage(image);

      const formData = new FormData();
      formData.append('image', compressedImage); // Usar a imagem comprimida

      const response = await api.post(`product/upload/image`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      await handleSentMessage({
        imageUrl: response.data.imageUrl,
        message: address,
      });

      await handleSentMessage({
        message: payment.method,
      });

      await api.put(`product/${productSelected.id}`, {
        address,
        print: response.data.imageUrl,
        paymentMethod: payment.method,
        status: 'has_informations',
      });

      if (!productSelected.agentId) {
        // Alocar um agente para o produto
        const responseAgent = await api.get(
          `product/allocate/${productSelected.id}`,
        );

        if (responseAgent.data.allocated) {
          addToast({
            title: 'Agente conectado!',
            description: 'Estamos te redirecionando...',
            type: 'success',
          });

          setTimeout(() => {
            push(`chat/${productSelected.id}`);
          }, 3000);
        } else {
          addToast({
            title: 'Nenhum agente disponível no momento',
            description: 'Tente novamente mais tarde.',
          });
          push(`/`);
        }
      }
    } catch (error) {
      console.error('Erro ao fazer upload:', error);
      addToast({ title: 'Erro ao enviar a imagem', type: 'error' });
    } finally {
      setTimeout(() => {
        setUploading(false); // Finaliza o estado de upload
      }, 3000);
    }
  };

  return (
    <S.Container>
      <S.Content>
        <S.WhatsAppLinkContainer>
          <a
            href="https://chat.whatsapp.com/H7a0vZBAGUcG6TEiQR8zOv"
            target="_blank"
            rel="noopener noreferrer"
          >
            Entre em nosso grupo do WhatsApp para receber avisos importantes e
            de descontos.
          </a>
          <div>
            <img src={qrcodewpp} alt="" />
          </div>
        </S.WhatsAppLinkContainer>
        {products.length > 0 && (
          <S.SectionTitle>
            Aqui estão seus cupons
            <S.Description>
              {`Agora é só escolher seu pedido, enviar um print dele pra nossa
            equipe e economizar!!! 🤑`}
            </S.Description>
          </S.SectionTitle>
        )}

        {products.length > 0 ? (
          <>
            <S.ProductList>
              {productSelected && (
                <S.ClientDetailsContainer>
                  <S.ClientDetailsContent>
                    {uploading ? (
                      <div style={{ textAlign: 'center' }}>
                        <Lottie
                          options={{
                            autoplay: true,
                            loop: true,
                            animationData: animationLoading,
                            rendererSettings: {
                              preserveAspectRatio: 'xMidYMid slice',
                            },
                          }}
                          width={100}
                          height={100}
                        />
                        Aguarde, estamos conectando você
                        <br />
                        com um de nossos agentes...
                      </div>
                    ) : (
                      <>
                        <MiniCoupon
                          product={productSelected}
                          handleSetProductSelected={setProductSelected}
                        />

                        <S.InfoTitle>
                          1. Envie o print do seu pedido
                        </S.InfoTitle>

                        <S.UploadContainer>
                          <S.InputUpload
                            id="inputUpload"
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange}
                          />
                          <S.LabelInputImg htmlFor="inputUpload">
                            <img src={preview || imgUpload} alt="" />
                          </S.LabelInputImg>

                          <S.UploadInformations>
                            {image ? (
                              <>
                                <span className="title">Imagem carregada!</span>
                                <span className="info">
                                  Clique na imagem se quiser alterar
                                </span>
                              </>
                            ) : (
                              <>
                                <span className="info">
                                  Tire o print na sacola do iFood
                                </span>
                              </>
                            )}
                            <a
                              href="https://incentivos.s3.us-east-2.amazonaws.com/images/print.png"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Visualizar exemplo
                            </a>
                          </S.UploadInformations>
                        </S.UploadContainer>

                        <S.InfoTitle>
                          2. Informe o endereço completo
                        </S.InfoTitle>

                        <S.InputAddress
                          onChange={e => setAddress(e.target.value)}
                          placeholder="Endereço para receber o pedido"
                        />

                        <S.InfoTitle>
                          3. Como você quer pagar a diferença?
                        </S.InfoTitle>

                        <S.PaymentMethodContainer>
                          {payments.map(p => (
                            <S.Payment
                              active={p.selected}
                              key={p.method}
                              onClick={() =>
                                setPayments(oldState =>
                                  oldState.map(payment =>
                                    payment.method === p.method
                                      ? { ...payment, selected: true }
                                      : { ...payment, selected: false },
                                  ),
                                )
                              }
                            >
                              <img src={p.logo} alt="" />
                              <span>{p.method}</span>
                            </S.Payment>
                          ))}
                        </S.PaymentMethodContainer>

                        <S.Buttons>
                          <S.ButtonCancel
                            onClick={() => setProductSelected(undefined)}
                          >
                            Cancelar
                          </S.ButtonCancel>
                          {image && address && (
                            <S.ButtonContinue onClick={handleSubmit}>
                              Continuar
                            </S.ButtonContinue>
                          )}
                        </S.Buttons>
                      </>
                    )}
                  </S.ClientDetailsContent>
                </S.ClientDetailsContainer>
              )}
              {products.map(p => (
                <MiniCoupon
                  key={p.id}
                  product={p}
                  handleSetProductSelected={setProductSelected}
                  showButton
                />
                // <S.Product key={p.id} href={`chat/${p.id}`}>
                //   <S.HeaderContainer>
                //     <S.Coupon>
                //       <img src={couponSvg} alt="" />
                //       <span>{`${toMoney(p.payment.value)} `}</span>
                //     </S.Coupon>

                //     {p.payment.price && (
                //       <S.ValueContainer>
                //         <FiCheck />
                //         {`${toMoney(p.payment.price)}`}
                //       </S.ValueContainer>
                //     )}
                //   </S.HeaderContainer>

                //   <S.DetailsContainer>
                //     <S.Date>{`Iniciado ${formatDate(p.createdAt)}`}</S.Date>

                //     {p.payment.status === 'refunded' && (
                //       <S.Date>{`Reembolsado ${formatDate(p.closedAt)}`}</S.Date>
                //     )}

                //     {p.payment.status !== 'refunded' && p.closedAt && (
                //       <S.Date>{`Finalizado ${formatDate(p.closedAt)}`}</S.Date>
                //     )}

                //     {!p.closedAt && p.payment.status === 'approved' && (
                //       <S.Date className="pending">Em andamento</S.Date>
                //     )}
                //   </S.DetailsContainer>
                // </S.Product>
              ))}
            </S.ProductList>
          </>
        ) : (
          searched && (
            <S.Empty>
              <p>Você ainda não adquiriu cupons :(</p>
              <S.Link href="/">Ir para cupons</S.Link>
              <Lottie
                options={{
                  autoplay: true,
                  loop: true,
                  animationData,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                  },
                }}
                width={300}
              />
            </S.Empty>
          )
        )}
      </S.Content>
    </S.Container>
  );
};

export default Coupons;

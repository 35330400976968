import React from 'react';
import copy from 'copy-to-clipboard';
import * as S from './styles';

type IProps = {
  code: string;
  remaining: number;
};

const IndicationBanner: React.FC<IProps> = ({ code, remaining }) => {
  return (
    <S.Container>
      <span>
        {`Você pode convidar ${remaining} ${
          remaining === 1 ? 'amigo' : 'amigos'
        } com seu link de indicação: `}
      </span>
      <S.ButtonCode onClick={() => copy(`https://cupomshop.com/?code=${code}`)}>
        Copiar convite
      </S.ButtonCode>
    </S.Container>
  );
};

export default IndicationBanner;

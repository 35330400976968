import React, { useCallback, useEffect, useState } from 'react';
import { FiShoppingBag } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { IoTicketOutline } from 'react-icons/io5';
import * as S from './styles';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

const routes = [
  {
    path: '/',
    icon: FiShoppingBag,
  },
  {
    path: '/cupons',
    icon: IoTicketOutline,
  },
];

const Header: React.FC = () => {
  const history = useHistory();
  const { isTokenExpired } = useAuth();
  const { addToast } = useToast();

  const [routeActive, setRouteActive] = useState('');

  const handleLogout = useCallback(() => {
    localStorage.clear();
    window.location.reload();
  }, []);

  useEffect(() => {
    setRouteActive(history.location.pathname);
  }, [history.location.pathname]);

  const handleNavigate = useCallback(
    (path: string) => {
      setRouteActive(path);
      history.push(path);
    },
    [history],
  );

  useEffect(() => {
    const sessionExpired = isTokenExpired();
    if (sessionExpired) {
      addToast({
        title: 'Você deve entrar na sua conta novamente!',
      });

      handleLogout();
    }
  }, [addToast, handleLogout, isTokenExpired]);

  return (
    <S.Container>
      <S.Content>
        <S.TopContent>
          {routes.map(r => (
            <S.IconContainer
              onClick={() => handleNavigate(r.path)}
              key={r.path}
              active={routeActive === r.path}
            >
              <r.icon size={20} />
            </S.IconContainer>
          ))}
        </S.TopContent>

        <S.BottomContent>
          <S.IconContainer onClick={() => handleLogout()} active={false}>
            <S.Logout size={20} />
          </S.IconContainer>
        </S.BottomContent>
      </S.Content>
    </S.Container>
  );
};

export default Header;

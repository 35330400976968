import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 16px;

  gap: 10px;

  background-color: red;

  z-index: 1;

  width: 100%;

  background-color: #000;
  color: #fff;
  font-size: 12px;
  text-align: center;

  max-height: max-content;

  border-radius: 20px;
`;

export const ButtonCode = styled.div`
  background-color: var(--primary);
  color: #000;
  padding: 5px 10px;
  cursor: pointer;

  border-radius: 10px;
`;

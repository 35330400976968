import React, { useCallback } from 'react';

import * as S from './styles';

import background from '../../assets/background.png';
import CouponSvg from '../../assets/coupon.svg';
import { IProduct } from '../../DTOS/IProduct';
import { toMoney } from '../../utils/toMoney';
import { formatDate } from '../../utils/formatDate';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';

type MiniCouponProps = {
  product: IProduct;
  handleSetProductSelected(product: IProduct): void;
  showButton?: boolean;
};

const MiniCoupon: React.FC<MiniCouponProps> = ({
  product,
  handleSetProductSelected,
  showButton,
}) => {
  const { addToast } = useToast();

  const handleConectAgent = useCallback(async () => {
    try {
      const response = await api.get(`product/allocate/${product.id}`);

      if (response.data.allocated) {
        addToast({
          title: 'Agente conectado!',
          description: 'Estamos te redirecionando...',
          type: 'success',
        });

        setTimeout(() => {
          window.open(`/chat/${product.id}`, '_blank');
        }, 3000);
      } else {
        addToast({
          title: 'Nenhum agente disponível no momento',
          description: 'Tente novamente mais tarde.',
        });
      }
    } catch (err) {
      addToast({
        title: 'Nenhum agente online, tente novamente mais tarde!',
        type: 'success',
      });
    }
  }, [addToast, product.id]);

  return (
    <S.Container style={{ backgroundImage: `url(${background})` }}>
      <div className="backgroundShadow" />
      <div className="backgroundShadowGradient" />
      <div className="backgroundShadowGradient" />

      <S.FirstContent>
        <S.Informations>
          <S.InfoContainer>
            <S.InfoText style={{ fontWeight: 700, marginBottom: 8 }}>
              {product.status === 'approved' && 'Pronto para usar!'}
              {product.status === 'done' && 'Cupom já utilizado.'}
              {product.status === 'need_refund' && 'Pedido de reembolso'}
              {product.status === 'has_informations' && 'Pedido preenchido'}
              {product.status === 'has_agent' && 'Pedido iniciado'}
              {product.status === 'refunded' && 'Pedido reembolsado!'}
            </S.InfoText>
          </S.InfoContainer>

          <S.InfoContainer>
            <S.InfoText style={{ fontWeight: 700, marginBottom: 8 }}>
              {product.status === 'approved' &&
                `Para utilizar este cupom você precisa montar um pedido mínimo de ${toMoney(
                  product.payment.value + 15,
                )}`}
              {product.status === 'done' &&
                `Pedido feito ${formatDate(product.closedAt)}.`}
              {product.status === 'need_refund' &&
                `Aguarde até que seu pedido de reembolso seja concluido!`}
              {product.status === 'has_informations' &&
                'Informações do pedido recebidas. Conecte a um agente.'}
              {product.status === 'has_agent' &&
                'Continue com o andamento do pedido'}

              {product.status === 'refunded' &&
                `O valor de ${toMoney(
                  product.payment.price,
                )} já foi reembolsado.`}
            </S.InfoText>
          </S.InfoContainer>
        </S.Informations>

        {showButton && (
          <S.Buttons>
            {product.status === 'approved' && (
              <S.Button onClick={() => handleSetProductSelected(product)}>
                Usar
              </S.Button>
            )}

            {product.status === 'done' && (
              <S.Button
                onClick={() => window.open(`/chat/${product.id}`, '_blank')}
              >
                Visualizar
              </S.Button>
            )}

            {product.status === 'need_refund' && (
              <span style={{ fontSize: 12, color: '#FFB800' }}>
                Aguardando reembolso
              </span>
            )}

            {product.status === 'has_informations' && (
              <S.Button onClick={handleConectAgent}>Solicitar agente</S.Button>
            )}
            {product.status === 'has_agent' && (
              <S.Button
                onClick={() => window.open(`/chat/${product.id}`, '_blank')}
              >
                Continuar
              </S.Button>
            )}
          </S.Buttons>
        )}
      </S.FirstContent>

      <S.LastContent>
        <S.CouponSvg src={CouponSvg} />
        <S.CouponTitle>CUPOM DE</S.CouponTitle>
        <S.CouponText>{`R$ ${product.payment.value}`}</S.CouponText>
      </S.LastContent>

      <div className="roundBottom" />
      <div className="roundBottomSupport" />
      <div className="lineContainer">
        <div className="line" />
        <div className="line" />
        <div className="line" />
        <div className="line" />
        <div className="line" />
      </div>
      <div className="roundTop" />
      <div className="roundTopSupport" />
    </S.Container>
  );
};

export default MiniCoupon;

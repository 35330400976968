/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-nested-ternary */
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FiImage, FiSend } from 'react-icons/fi';
import { useHistory, useParams } from 'react-router-dom';
import { setInterval } from 'timers';
import imageCompression from 'browser-image-compression';
import Lottie from 'react-lottie';
import { isMobile, isBrowser } from 'react-device-detect';
import * as S from './styles';
import api from '../../services/api';
import { IProduct } from '../../DTOS/IProduct';
import { IMessage } from '../../DTOS/IMessage';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import { formatDate } from '../../utils/formatDate';
import MiniCoupon from '../../components/MiniCoupon';
import { IAgent } from '../../DTOS/IAgent';
import productImg from '../../assets/coupon.svg';
import smsSong from '../../assets/songs/sms.mp3';

import animationData from '../../assets/lottie/waiting.json';

type IMessageProps = { message?: string; image: File | null };

const sms_audio = new Audio(smsSong);

// Chat Component
const Chat: React.FC = () => {
  const { push } = useHistory();
  const { addToast } = useToast();
  const { user } = useAuth();
  const { id } = useParams<{ id: string }>();

  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  // State management
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [product, setProduct] = useState<IProduct>();
  const [agent, setAgent] = useState<IAgent>();

  const [loading, setLoading] = useState(false);

  const [isWindowFocused, setIsWindowFocused] = useState<boolean>(true);

  const scrollToBottom = useCallback(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  }, []);

  const changeFavicon = (iconURL: string): void => {
    let link = document.querySelector<HTMLLinkElement>("link[rel='icon']");

    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.head.appendChild(link);
    }

    link.href = iconURL;
  };

  useEffect(() => {
    changeFavicon(productImg);

    const handleVisibilityChange = () => {
      setIsWindowFocused(!document.hidden);
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup do evento quando o componente é desmontado
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const refreshMessages = useCallback(async () => {
    const response = await api.get<IMessage[]>(`product/messages/${id}`);

    setMessages(oldState => {
      if (oldState.length !== response.data.length) {
        if (response.data[response.data.length - 1].sender_id === agent?.id) {
          sms_audio.play();
        }

        setTimeout(() => {
          scrollToBottom();
        }, 2000);
      }
      return response.data;
    });
  }, [agent, id, scrollToBottom]);

  useEffect(() => {
    if (!agent) return;

    const totalMessagesNotRead = messages.filter(
      m => !m.readAt && m.sender_id === agent.id,
    );

    if (!product) return;

    if (totalMessagesNotRead.length > 0) {
      if (isWindowFocused) {
        api.put(`product/${id}/${agent.id}/read/all`);
        document.title = `$${product.payment.value}`;
      } else {
        document.title = `(${totalMessagesNotRead.length}) Não lidas`;
      }
    } else {
      document.title = `$${product.payment.value}`;
    }
  }, [agent, id, isWindowFocused, messages, product]);

  useEffect(() => {
    refreshMessages();
    const intervalId = setInterval(refreshMessages, 2500);
    return () => clearInterval(intervalId);
  }, [refreshMessages]);

  const getProductAndAgent = useCallback(async () => {
    if (!id) {
      push('/');
      return;
    }

    const response = await api.get<IProduct>(`product/${id}`);

    if (!response.data || response.data.payment.status !== 'approved') {
      push('/');
    }

    setProduct(response.data);

    if (!response.data.agentId) {
      setAgent(undefined);
      return;
    }

    const agentResponse = await api.get<IAgent>(
      `agent/${response.data.agentId}`,
    );

    setAgent(agentResponse.data);
  }, [id, push]);

  useEffect(() => {
    getProductAndAgent();

    setInterval(() => {
      getProductAndAgent();
    }, 5000);
  }, [getProductAndAgent]);

  // Função para comprimir a imagem
  const compressImage = async (img: File): Promise<File> => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 800,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(img, options);
      return compressedFile;
    } catch (error) {
      console.error('Erro ao comprimir a imagem:', error);
      throw error;
    }
  };

  const handleSentMessage = useCallback(
    async ({ message, image }: IMessageProps) => {
      if (!message && !image) return;
      setLoading(true);

      try {
        // Comprimir a imagem antes do envio
        if (image) {
          const compressedImage = await compressImage(image);
          const formData = new FormData();
          formData.append('image', compressedImage); // Usar a imagem comprimida

          const response = await api.post(`product/upload/image`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });

          await api.post('product/message', {
            product_id: id,
            sender_id: user.id,
            content: message,
            imageUrl: response.data.imageUrl,
          });
        } else {
          await api.post('product/message', {
            product_id: id,
            sender_id: user.id,
            content: message,
          });
        }

        refreshMessages();
        setInputValue('');
        setLoading(false);
        setTimeout(scrollToBottom, 500);
      } catch {
        addToast({
          title: 'Não foi possível enviar mensageem',
          type: 'error',
        });
      }
    },
    [addToast, id, refreshMessages, scrollToBottom, user.id],
  );

  useEffect(() => {
    if (messages.length === 1 && agent && product) {
      api.post('product/message', {
        product_id: product.id,
        sender_id: agent.id,
        content: `Olá, aguarde um instante que já vou fazer seu pedido! FIQUE ATENTO A ESTE CHAT.`,
      });
    }
  }, [agent, handleSentMessage, messages.length, product]);

  const createdAt = useMemo(
    () => (product ? `Iniciado ${formatDate(product.createdAt)}` : ''),
    [product],
  );
  const closedAt = useMemo(
    () =>
      product && product.closedAt
        ? `Finalizado ${formatDate(product.closedAt)}`
        : '',
    [product],
  );

  useEffect(() => {
    if (product && !product.device) {
      const device = isBrowser ? 'PC' : isMobile ? 'Celular' : 'Tablet';

      api.put(`product/${product.id}`, {
        device,
      });
    }
  }, [product]);

  useEffect(() => {
    if (!product) return;
    if (!product.agentId && !product.viewed) {
      push('/cupons');
    }
  }, [product, push]);

  return (
    <S.Container>
      {product && (
        <S.Chat>
          <S.Header>
            {user && (
              <MiniCoupon
                handleSetProductSelected={() => null}
                product={product}
              />
            )}

            {agent && (
              <S.ChatInfo>
                <div className="chat">
                  <strong>{`Chat nº ${product.payment.id}`}</strong>
                  <span>{product.closedAt ? closedAt : createdAt}</span>
                </div>
                <div className="agent">
                  <div>
                    <strong>
                      {product.closedAt ? 'Atendido por' : 'Atendente'}
                    </strong>
                    <span>{agent.agentName}</span>
                  </div>
                  <img src={agent.img} alt="" />
                  {!product.closedAt && <S.Online online={agent.online} />}
                </div>
              </S.ChatInfo>
            )}
          </S.Header>

          <S.Messages ref={messagesEndRef}>
            {messages.map(message => (
              <S.MessageContainer
                fromUser={message.sender_id === product.userId}
                key={message.id}
              >
                {message.sender_id === product.agentId && agent && (
                  <S.Agent>
                    <img src={agent.img} alt="" />
                  </S.Agent>
                )}
                <S.Message fromUser={message.sender_id === product.userId}>
                  {message.imageUrl && <img src={message.imageUrl} alt="" />}

                  {message.content && message.content}
                </S.Message>
              </S.MessageContainer>
            ))}
          </S.Messages>

          {product.agentId && (
            <InputBar
              loading={loading}
              value={inputValue}
              onChange={setInputValue}
              onSend={handleSentMessage}
            />
          )}
        </S.Chat>
      )}
    </S.Container>
  );
};

// Reusable InputBar component for normal chat messages
const InputBar: React.FC<{
  value: string;
  onChange: (value: string) => void;
  onSend: (message: IMessageProps) => void;
  loading: boolean;
}> = ({ value, onChange, onSend, loading }) => {
  const [image, setImage] = useState<File | null>(null);
  const [preview, setPreview] = useState<string | null>(null);

  const handleImageChange = async (
    e: ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    const file = e.target.files?.[0];
    if (file) {
      setImage(file);
      const reader = new FileReader();
      reader.onloadend = () => setPreview(reader.result as string);
      reader.readAsDataURL(file);
    }
  };

  return (
    <S.TalkBar>
      <S.InputContainer>
        <S.Input
          value={value}
          onChange={e => onChange(e.target.value)}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              onSend({
                message: value,
                image: image || null,
              });
              setImage(null);
              setPreview(null);
            }
          }}
          placeholder="Digite aqui..."
        />
        {preview && <img src={preview} alt="" />}

        <S.Actions>
          {loading ? (
            <Lottie
              options={{
                autoplay: true,
                loop: true,
                animationData,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              width={40}
              height={40}
            />
          ) : (
            <>
              <S.LabelInputImg htmlFor="inputUpload">
                <S.InputUpload
                  id="inputUpload"
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                />

                <S.ActionButton>
                  <S.LabelInputImg htmlFor="inputUpload">
                    <FiImage
                      color={preview ? '#66d37a' : '#00000030'}
                      size={22}
                    />
                  </S.LabelInputImg>
                </S.ActionButton>
              </S.LabelInputImg>

              <S.ActionButton
                onClick={() => {
                  onSend({
                    message: value,
                    image: image || null,
                  });
                  setImage(null);
                  setPreview(null);
                }}
                disabled={!value && !preview}
              >
                <FiSend size={20} />
              </S.ActionButton>
            </>
          )}
        </S.Actions>
      </S.InputContainer>
    </S.TalkBar>
  );
};

export default Chat;

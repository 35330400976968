import React, { useCallback, useEffect, useState } from 'react';
import * as S from './styles';
import Coupon from '../../components/Coupon';
import background1 from '../../assets/backgroundcard1.png';
import background2 from '../../assets/backgroundcard2.png';
import background3 from '../../assets/backgroundcard3.png';
import background4 from '../../assets/backgroundcard4.png';
import background5 from '../../assets/backgroundcard5.png';
import background6 from '../../assets/backgroundcard6.png';
import background7 from '../../assets/backgroundcard7.png';
import IndicationBanner from '../../components/IndicationBanner';
import { IUser } from '../../DTOS/IUser';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import { ICoupon } from '../../DTOS/ICoupon';
import qrcodegroupimg from '../../assets/qrcodegroup.png';

const backgrounds = [
  background5,
  background2,
  background3,
  background4,
  background1,
  background6,
  background7,
];

type CouponsCountProps = {
  totalToSearch: number;
  totalVerified: {
    total: number;
    hasCoupons: number;
    withoutCoupons: number;
    percentComplete: number;
  };
  totalCouponsQuantity: {
    _id: number;
    count: number;
    percent: number;
  }[];
  availables: number;
  applieds: number;
};

const Home: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const [userFromApi, setUserFromApi] = useState<IUser>();
  const [coupons, setCoupons] = useState<ICoupon[]>([]);
  const [agentsOnline, setAgentsOnline] = useState(1);

  const fetchCouponsCount = useCallback(async () => {
    try {
      const couponsImperio = await api.get<CouponsCountProps>(
        'https://imperioback-719b59389142.herokuapp.com/email/scanner/coupons/count',
      );

      const couponsShop = await api.get<ICoupon[]>(`product/coupons/all`);

      const couponsAvailable = couponsShop.data.filter(coupon => {
        const couponImperioFound =
          couponsImperio.data.totalCouponsQuantity.find(
            // eslint-disable-next-line no-underscore-dangle
            c => c._id === coupon.value,
          );

        return couponImperioFound;
      });

      setCoupons(couponsAvailable.sort((a, b) => b.value - a.value));
    } catch (error) {
      addToast({ title: 'Erro ao buscar contagem de cupons', type: 'error' });
    }
  }, [addToast]);

  const getOnline = useCallback(async () => {
    try {
      const response = await api.get(`agent/has/online`);
      setAgentsOnline(response.data.agentsOnline);
    } catch (err) {
      addToast({
        title: 'Não foi possivel buscar quantidade de agents online',
        type: 'error',
      });
    }
  }, [addToast]);

  const getUser = useCallback(async () => {
    try {
      const response = await api.get<IUser>(`user/${user.id}`);
      setUserFromApi(response.data);
    } catch (err) {
      addToast({
        title: 'Não foi possivel buscar usuário',
        type: 'error',
      });
    }
  }, [addToast, user.id]);

  useEffect(() => {
    getOnline();
    getUser();
    fetchCouponsCount();

    setInterval(() => {
      getOnline();
      fetchCouponsCount();
    }, 30000);
  }, [fetchCouponsCount, getOnline, getUser]);

  return (
    <S.Container>
      <S.Content>
        {userFromApi &&
          userFromApi.referral.indications < userFromApi.referral.limit && (
            <IndicationBanner
              code={userFromApi?.referral.code}
              remaining={
                userFromApi.referral.limit - userFromApi.referral.indications
              }
            />
          )}

        {agentsOnline > 0 ? (
          <S.SectionTitle>
            Escolha seu cupom
            <S.Description>
              Assim que adquirir seu cupom, um agente da nossa equipe fará o
              pedido com você.
            </S.Description>
          </S.SectionTitle>
        ) : (
          <S.SectionTitle>
            Estamos sem agentes online
            <br />
            neste momento...
            <S.Description>
              <a
                href="https://chat.whatsapp.com/H7a0vZBAGUcG6TEiQR8zOv"
                target="_blank"
                rel="noopener noreferrer"
              >
                Entre em nosso grupo do WhatsApp para receber avisos importantes
                e de descontos.
              </a>
            </S.Description>
            <img src={qrcodegroupimg} alt="" />
          </S.SectionTitle>
        )}

        <S.CouponsContainer>
          {coupons?.length > 0 &&
            agentsOnline > 0 &&
            coupons.map((c, i) => (
              <Coupon
                id={c.id}
                key={c.id}
                coupon={c.value}
                price={c.price}
                minOrder={c.minOrder}
                background={backgrounds[i]}
              />
            ))}
        </S.CouponsContainer>
      </S.Content>
    </S.Container>
  );
};

export default Home;

import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  :root {
    --primary: #66d37a;
    --primary20: #66d37a20;
    --primary40: #66d37a40;
    --primary60: #66d37a60;

    --danger: #FF1818;
    --danger20: #FF181820;

    --alert: #FFB800;

    --container: #101010;
    --content: #131A18;
    --background: #F6F6F6;

    --greyTextContainer: #ffffff60;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  ul {
  list-style: none;
    li {
  list-style: none;
    }
  }

  body {
    background: linear-gradient(145deg, #f0f3f5, #d8e0e7);

    color: #FFF;
    -webkit-font-smoothing: antialiased;
  }

  body, input, button, span, textarea, div {
    font-family: 'Poppins', serif;
    font-size: 16px;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
    font-family: 'Roboto Slab', serif;
  }

  button {
    cursor: pointer;
  }
`;
